import {request} from "../../../util/Request";
import moment from "moment-timezone";
import Error from "../../../util/Error";
import {mapState} from "vuex";

const FORM_STATE = {
    id: null,
    comments: null,
    status: null,
    _method: 'post',
}

export default {
    data() {
        return {
            formFields: {...FORM_STATE},
            selectedLoad: null
        }
    },
    methods: {
        handleInTransitClick(record) {
            this.formFields.status = 6
            this.formFields.edit = 0
            this.formFields.id = record.id
            this.selectedLoad = record
            this.formFields.created_at = moment()
        },
        handleUnLoadClick(record) {
            this.formFields.edit = 0
            this.formFields.status = 7
            this.formFields.created_at = moment()
            this.formFields.id = record
            this.selectedLoad = record
        },
        handleAcceptClick(record) {
            this.formFields.edit = 0
            this.formFields.status = 4
            this.formFields.id = record.id
            this.selectedLoad = record
        },
        handleRejectClick(record) {
            this.formFields.edit = 0
            this.formFields.status = 3
            this.formFields.id = record.id
            this.selectedLoad = record
        },
        handleAcceptedEditClick(record) {
            this.formFields.status = 4 // just temporary
            this.formFields.created_at = null // just temporary
            this.formFields.edit = 1
            this.formFields.id = record.id
            this.formFields.driver_id = record.driver.id
            this.formFields.transport_vehicle_id = record.transport_vehicle.id
            this.formFields.estimated_loading_time = record.estimated_loading_time ? moment(record.estimated_loading_time) : null
            this.formFields.estimated_unloading_time = record.estimated_unloading_time ? moment(record.estimated_unloading_time) : null
            this.selectedLoad = record
        },
        async handleReexportLoadResetClick(record) {
            if(this.global.pendingRequests > 0) {
                return false;
            }

            try {
                const response = await request({
                    url: '/reexport/loads/carrier/supplier/reset',
                    method: 'post',
                    data: {
                        id: record.id
                    },
                })

                this.itemUpdated()
                this.loadList()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }

                this.handleServerError(error)
            }
        }
    },
    computed: {
        ...mapState([
            'global',
        ]),
        title() {
            if (this.formFields.status == 6) {
                return this.$t('title.loadInTransit')
            } else if (this.formFields.status == 7) {
                return this.$t('title.loadUnloaded')
            } else if (this.formFields.status == 4) {
                return this.$t('title.acceptLoad')
            } else if (this.formFields.status == 3) {
                return this.$t('title.rejectLoad')
            }
        }
    }
}
